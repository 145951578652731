export const AppRoutes = {
  MAIN: '/',
  HOME: '/dashboard',
  LOGIN: '/login',
  MY_PROFILE: '/profile',
  SETTINGS: '/setting',
  EXAMS: '/exams',
  NOT_FOUND: '/notFound',
  /*
----------------
    USERS  
----------------
  */
  /*
  --------------------------------------
    App Users for Admin & Super - Admin
  -------------------------------------
  */
  APP_USERS: '/users/appUsers',
  APP_USERS_EDIT: '/users/appUsers/edit-users/:id',
  APP_USERS_VIEW: '/users/appUsers/view-users/:id/:source',
  APP_USERS_SUBSCRIPTION: '/users/appUsers/manage-subsciption/:id',
  /*
  -------------------------------
    Partner User  for Super Admin
  -------------------------------
  */
  PARTNER_USERS: '/users/partnerUsers',

  /*
  -----------------------------------------
  Particular Partner or organisation Users
  -----------------------------------------
  */
  USERS: '/user',
  MANAGE_SUBSCRIPTION: '/manage-subsciption',
  VIEW_INDIVIDUAL_USER_DETAILS: '/user/viewSubscription/:id/:partnerID',
  MANAGE_INDIVIDUAL_USER_SUBSCRIPTION:
    '/user/manageSubscription/:id/:partnerID',
  /*
 ----------------
 ADMIN
 ----------------
 */
  ADMIN: '/admin',
  GMAT_ADMIN: '/gmat-admin',
  ESG_ADMIN: '/esg-admin',

  EA_ADMIN: '/ea-admin',
  EDIT_ADMIN: '/admin/edit_admin/:id',
  NEW_ADMIN: '/admin/new-admin',
  NEW_GMAT_ADMIN: '/gmat-admin/new-gmatadmin',
  NEW_EA_ADMIN: '/ea-admin/new-eaadmin',

  /*
-----------------------------
PARTNER
----------------------------
*/

  PARTNER: '/partner',
  PARTNER_SUBSCRIPTION: '/partner/manage-subsciption/:id',
  PARTNER_DETAILS: '/partner/viewDetails/:id/:source',
  PARTNER_PREMIUM_USERS: '/last-month-users-stats',
  /*
---------------------------------
                  EXAMS
---------------------------------
  */
  EXAM_CFA1: '/exams/cfa1',
  EXAM_CFA1_2022: '/exams/cfa1-2022',
  EXAM_CFA1_2023: '/exams/cfa1-2023',
  EXAM_CFA1_2025: '/exams/cfa1-2025',
  EXAM_CFA2: '/exams/cfa2',
  EXAM_CFA2_2022: '/exams/cfa2-2022',
  EXAM_CFA2_2023: '/exams/cfa2-2023',
  EXAM_CFA2_2025: '/exams/cfa2-2025',

  EXAM_CFA3: '/exams/cfa3',
  EXAM_FRM1: '/exams/frm1',
  EXAM_FRM2: '/exams/frm2',
  EXAM_ACTUARIALP: '/exams/ACTUARIALP',
  EXAM_ACTUARIALFM: '/exams/ACTUARIALFM',
  EXAM_ACTUARIALIFM: '/exams/ACTUARIALIFM',
  EXAM_GMAT: '/exams/gmat',
  EXAM_EA: '/exams/ea',

  /*
-----------------------------------------------------------------------------------------------------------
                                     {{        ESG EXAM ROUTES        }} 
-----------------------------------------------------------------------------------------------------------
*/

  ESG_CHAPTERS: '/exams/esg/chapter',
  ESG_CHAPTERS_NOTES: '/exams/esg/chapter/notes',
  ESG_QUESTIONS: '/exams/esg/questions',
  ESG_ADD_NEW_QUESTIONS: '/exams/esg/questions/new',
  ESG_EDIT_QUESTIONS: '/exams/esg/questions/edit/:id/:chapter_id',
  ESG_VIEW_QUESTIONS: '/exams/esg/questions/view/:id',
  ESG_EXAM: '/exams/esg',
  ESG_VIDEOS: '/exams/esg/videos',
  ESG_VIDEOS_ADD: '/exams/esg/videos/add',
  ESG_VIDEOS_EDIT: '/exams/esg/videos/edit/:id/:chapter_id',
  ESG_VIDEOS_REPORT_EDIT: '/exams/esg/videos/edit/:id/:chapter_id/:report_id',
  ESG_VIDEOS_UPDATE: '/exams/esg/videos/update/:id/:chapter_id',
  ESG_VIDEOS_VIEW: '/exams/esg/videos/view/:id',

  ESG_MOCK: '/exams/esg/mock',
  ESG_CBT_QUESTIONS: '/exams/esg/cbt/questions',
  ESG_CBT_QUESTIONS_UPDATE: '/exams/esg/cbt/questions/update/:id/:chapter_id',
  ESG_CBT_ADD_NEW_QUESTIONS: '/exams/esg/cbt/questions/new',
  ESG_CBT_VIEW_QUESTIONS: '/exams/esg/cbt/questions/view/:id',

  /* <=====================  CFA1  ======================>  */

  /*
---------------------------------
             CFA1_CHAPTERS
---------------------------------
  */
  CFA1_CHAPTERS: '/exams/cfa1/chapter',
  /*
 ---------------------------------
                CFA1_READINGS
 ---------------------------------
*/
  CFA1_READINGS: '/exams/cfa1/reading',
  /*
--------------------------------------
                  CFA1_QUESTIONS
-------------------------------------
  */
  CFA1_QUESTIONS: '/exams/cfa1/questions',
  CFA1_NEW_QUES: '/exams/cfa1/questions/new-ques',
  CFA1_QUES_UPDATE: '/exams/cfa1/questions/update/:id/:chapter_id',
  CFA1_QUES_VIEW: '/exams/cfa1/questions/view/:id',
  /*
---------------------------------
                  CFA1 -NOTES
---------------------------------
  */
  CFA1_NOTES: '/exams/cfa1/notes',
  CFA1_NEW_NOTES: '/exams/cfa1/notes/new-notes',
  CFA1_NOTES_UPDATE: '/exams/cfa1/notes/update/:id/:chapter_id',
  CFA1_NOTES_VIEW: '/exams/cfa1/notes/view/:id',

  /*
  ---------------------------------
                CFA1_VIDEOS
  ---------------------------------
*/
  CFA1_VIDEOS: '/exams/cfa1/videos',
  CFA1_VIDEOS_VIEW: '/exams/cfa1/videos/view/:id',
  CFA1_VIDEOS_UPDATE: '/exams/cfa1/videos/update/:id/:chapter_id',
  CFA1_NEW_VIDEOS: '/exams/cfa1/videos/new-video',

  /*
  ---------------------------------
                CFA1_ADDITIONAL_RESOURCES
  ---------------------------------
*/
  CFA1_ADDITIONAL_RESOURCES: '/exams/cfa1/additional-resources',
  CFA2_ADDITIONAL_RESOURCES: '/exams/cfa2/additional-resources',
  CFA3_ADDITIONAL_RESOURCES: '/exams/cfa3/additional-resources',

  // CFA1_VIDEOS_VIEW: '/exams/cfa1/videos/view/:id',

  // CFA1_NEW_VIDEOS: '/exams/cfa1/videos/new-video',

  /*
  ----------------------------
      CFA-1 MOCK EXAMS
  ----------------------------
  */
  CFA1_2022_MOCK_EXAMS: '/exams/cfa1-2022/mock-exams',
  CFA1_2023_MOCK_EXAMS: '/exams/cfa1-2023/mock-exams',

  CFA1_MOCK_EXAMS: '/exams/cfa1/mock-exams',

  //=================================== CFA 2022 ======================================
  /*
---------------------------------
             CFA1_2022_CHAPTERS
---------------------------------
  */
  CFA1_2022_CHAPTERS: '/exams/cfa1-2022/chapter',
  /*
---------------------------------
              CFA1_2022_READINGS
---------------------------------
*/
  CFA1_2022_READINGS: '/exams/cfa1-2022/reading',
  /*
--------------------------------------
                CFA1_2022_QUESTIONS
-------------------------------------
*/
  CFA1_2022_QUESTIONS: '/exams/cfa1-2022/questions',
  CFA1_2022_NEW_QUES: '/exams/cfa1-2022/questions/new-ques',
  CFA1_2022_QUES_UPDATE: '/exams/cfa1-2022/questions/update/:id/:chapter_id',
  CFA1_2022_QUES_VIEW: '/exams/cfa1-2022/questions/view/:id',
  /*
---------------------------------
                NOTES
---------------------------------
*/
  CFA1_2022_NOTES: '/exams/cfa1-2022/notes',
  CFA1_2022_NEW_NOTES: '/exams/cfa1-2022/notes/new-notes',
  CFA1_2022_NOTES_UPDATE: '/exams/cfa1-2022/notes/update/:id/:chapter_id',
  CFA1_2022_NOTES_VIEW: '/exams/cfa1-2022/notes/view/:id',

  /*
---------------------------------
              CFA1_2022_VIDEOS
---------------------------------
*/
  CFA1_2022_VIDEOS: '/exams/cfa1-2022/videos',
  CFA1_2022_VIDEOS_VIEW: '/exams/cfa1-2022/videos/view/:id',
  CFA1_2022_VIDEOS_UPDATE: '/exams/cfa1-2022/videos/update/:id/:chapter_id',
  CFA1_2022_NEW_VIDEOS: '/exams/cfa1-2022/videos/new-video',

  //=================================== CFA 2023 ======================================
  /*
---------------------------------
             CFA1_2023_CHAPTERS
---------------------------------
  */
  CFA1_2023_CHAPTERS: '/exams/cfa1-2023/chapter',
  /*
---------------------------------
            CFA1 2023 READING
---------------------------------
*/
  CFA1_2023_READINGS: '/exams/cfa1-2023/reading',
  /*
--------------------------------------
              CFA1_2023_QUESTIONS
-------------------------------------
*/
  CFA1_2023_QUESTIONS: '/exams/cfa1-2023/questions',
  CFA1_2023_NEW_QUES: '/exams/cfa1-2023/questions/new-ques',
  CFA1_2023_QUES_UPDATE: '/exams/cfa1-2023/questions/update/:id/:chapter_id',
  CFA1_2023_QUES_VIEW: '/exams/cfa1-2023/questions/view/:id',
  /*
---------------------------------
              NOTES
---------------------------------
*/
  CFA1_2023_NOTES: '/exams/cfa1-2023/notes',
  CFA1_2023_NEW_NOTES: '/exams/cfa1-2023/notes/new-notes',
  CFA1_2023_NOTES_UPDATE: '/exams/cfa1-2023/notes/update/:id/:chapter_id',
  CFA1_2023_NOTES_VIEW: '/exams/cfa1-2023/notes/view/:id',

  /*
---------------------------------
            CFA1_2023_VIDEOS
---------------------------------
*/
  CFA1_2023_VIDEOS: '/exams/cfa1-2023/videos',
  CFA1_2023_VIDEOS_VIEW: '/exams/cfa1-2023/videos/view/:id',
  CFA1_2023_VIDEOS_UPDATE: '/exams/cfa1-2023/videos/update/:id/:chapter_id',
  CFA1_2023_NEW_VIDEOS: '/exams/cfa1-2023/videos/new-video',

  /*       <=================  CFA2  ==================>  */
  /*
  ---------------------------------
                CFA2_CHAPTERS
  ---------------------------------
*/
  CFA2_CHAPTERS: '/exams/cfa2/chapter',

  /*
  ---------------------------------
                CFA2_CASES
  ---------------------------------
*/
  CFA2_CASES: '/exams/cfa2/cases',
  CFA2_NEW_CASES: '/exams/cfa2/cases/new-cases',
  CFA2_Cases_Update: '/exams/cfa2/cases/update/:id/:chapter_id',
  CFA2_CASES_VIEW: '/exams/cfa2/cases/view/:id',
  /*
  ---------------------------------
                CFA2_QUESTIONS
  ---------------------------------
*/
  CFA2_QUESTIONS: '/exams/cfa2/questions',
  CFA2_NEW_QUES: '/exams/cfa2/questions/new-ques',
  CFA2_QUES_UPDATE: '/exams/cfa2/questions/update/:id/:chapter_id',
  CFA2_QUES_VIEW: '/exams/cfa2/questions/view/:id',
  /*
  ---------------------------------
               CFA2_NOTES
  ---------------------------------
*/
  CFA2_NOTES: '/exams/cfa2/notes',
  CFA2_NEW_NOTES: '/exams/cfa2/notes/new-notes',
  CFA2_NOTES_UPDATE: '/exams/cfa2/notes/update/:id/:chapter_id',
  CFA2_NOTES_VIEW: '/exams/cfa2/notes/view/:id',
  /*
  ---------------------------------
               CFA2_VIDEOS
  ---------------------------------
*/
  CFA2_VIDEOS: '/exams/cfa2/videos',
  CFA2_NEW_VIDEOS: '/exams/cfa2/videos/new-video',
  CFA2_VIDEOS_UPDATE: '/exams/cfa2/videos/update/:id/:chapter_id',
  CFA2_VIDEOS_VIEW: '/exams/cfa2/videos/view/:id',

  /*
  ----------------------------
      CFA-2 MOCK EXAMS
  ----------------------------
  */
  CFA2_MOCK_EXAMS: '/exams/cfa2/mock-exams',

  /*       <=================  CFA2 2022 ==================>  */
  /*
  ---------------------------------
                CFA2_CHAPTERS
  ---------------------------------
*/
  CFA2_2022_CHAPTERS: '/exams/cfa2-2022/chapter',

  /*
  ---------------------------------
                CFA2_READINGS
  ---------------------------------
*/
  CFA2_2022_READINGS: '/exams/cfa2-2022/readings',
  /*
---------------------------------
              CFA2_2022_CASES
---------------------------------
*/
  CFA2_2022_CASES: '/exams/cfa2-2022/cases',
  CFA2_2022_NEW_CASES: '/exams/cfa2-2022/cases/new-cases',
  CFA2_2022_Cases_Update: '/exams/cfa2-2022/cases/update/:id/:chapter_id',
  CFA2_2022_CASES_VIEW: '/exams/cfa2-2022/cases/view/:id',
  /*
---------------------------------
              CFA2_2022_QUESTIONS
---------------------------------
*/
  CFA2_2022_QUESTIONS: '/exams/cfa2-2022/questions',
  CFA2_2022_NEW_QUES: '/exams/cfa2-2022/questions/new-ques',
  CFA2_2022_QUES_UPDATE: '/exams/cfa2-2022/questions/update/:id/:chapter_id',
  CFA2_2022_QUES_VIEW: '/exams/cfa2-2022/questions/view/:id',

  /*
----------------------------
    CFA-2 MOCK EXAMS
----------------------------
*/
  CFA2_2022_MOCK_EXAMS: '/exams/cfa2-2022/mock-exams',
  /*
  ---------------------------------
               CFA2_2022_NOTES
  ---------------------------------
*/
  CFA2_2022_NOTES: '/exams/cfa2-2022/notes',
  CFA2_2022_NEW_NOTES: '/exams/cfa2-2022/notes/new-notes',
  CFA2_2022_NOTES_UPDATE: '/exams/cfa2-2022/notes/update/:id/:chapter_id',
  CFA2_2022_NOTES_VIEW: '/exams/cfa2-2022/notes/view/:id',
  /*
  ---------------------------------
               CFA2_2022_VIDEOS
  ---------------------------------
*/
  CFA2_2022_VIDEOS: '/exams/cfa2-2022/videos',
  CFA2_2022_NEW_VIDEOS: '/exams/cfa2-2022/videos/new-video',
  CFA2_2022_VIDEOS_UPDATE: '/exams/cfa2-2022/videos/update/:id/:chapter_id',
  CFA2_2022_VIDEOS_VIEW: '/exams/cfa2-2022/videos/view/:id',

  /*       <=================  CFA2 2023 ==================>  */
  /*
  ---------------------------------
                CFA2_CHAPTERS
  ---------------------------------
*/
  CFA2_2023_CHAPTERS: '/exams/cfa2-2023/chapter',
  CFA2_2025_CHAPTERS: '/exams/cfa2/2025/chapter',

  /*
---------------------------------
              CFA2_READINGS
---------------------------------
*/
  CFA2_2023_READINGS: '/exams/cfa2-2023/readings',
  CFA2_2025_READINGS: '/exams/cfa2/2025/readings',
  CFA3_READINGS: '/exams/cfa3/readings',
  CFA3_2025_READINGS: '/exams/cfa3/2025/readings',

  /*
---------------------------------
            CFA2_2023_CASES
---------------------------------
*/
  CFA2_2023_CASES: '/exams/cfa2-2023/cases',
  CFA2_2023_NEW_CASES: '/exams/cfa2-2023/cases/new-cases',
  CFA2_2023_Cases_Update: '/exams/cfa2-2023/cases/update/:id/:chapter_id',
  CFA2_2023_CASES_VIEW: '/exams/cfa2-2023/cases/view/:id',
  /*
---------------------------------
            CFA2_2023_QUESTIONS
---------------------------------
*/
  CFA2_2023_QUESTIONS: '/exams/cfa2-2023/questions',
  CFA2_2023_NEW_QUES: '/exams/cfa2-2023/questions/new-ques',
  CFA2_2023_QUES_UPDATE: '/exams/cfa2-2023/questions/update/:id/:chapter_id',
  CFA2_2023_QUES_VIEW: '/exams/cfa2-2023/questions/view/:id',

  /*
----------------------------
  CFA-2 MOCK EXAMS
----------------------------
*/
  CFA2_2023_MOCK_EXAMS: '/exams/cfa2-2023/mock-exams',
  /*
---------------------------------
             CFA2_2023_NOTES
---------------------------------
*/
  CFA2_2023_NOTES: '/exams/cfa2-2023/notes',
  CFA2_2023_NEW_NOTES: '/exams/cfa2-2023/notes/new-notes',
  CFA2_2023_NOTES_UPDATE: '/exams/cfa2-2023/notes/update/:id/:chapter_id',
  CFA2_2023_NOTES_VIEW: '/exams/cfa2-2023/notes/view/:id',
  /*

  /*
---------------------------------
            CFA2_2025_CASES
---------------------------------
*/
  CFA2_2025_CASES: '/exams/cfa2/2025/cases',
  CFA2_2025_NEW_CASES: '/exams/cfa2/2025/cases/new-cases',
  CFA2_2025_Cases_Update: '/exams/cfa2/2025/cases/update/:id/:chapter_id',
  CFA2_2025_CASES_VIEW: '/exams/cfa2/2025/cases/view/:id',
  /*
---------------------------------
            CFA2_2025_QUESTIONS
---------------------------------
*/
  CFA2_2025_QUESTIONS: '/exams/cfa2/2025/questions',
  CFA2_2025_NEW_QUES: '/exams/cfa2/2025/questions/new-ques',
  CFA2_2025_QUES_UPDATE: '/exams/cfa2/2025/questions/update/:id/:chapter_id',
  CFA2_2025_QUES_VIEW: '/exams/cfa2/2025/questions/view/:id',

  /*
----------------------------
  CFA-2 MOCK EXAMS
----------------------------
*/
  CFA2_2025_MOCK_EXAMS: '/exams/cfa2/2025/mock-exams',
  /*
---------------------------------
             CFA2_2025_NOTES
---------------------------------
*/
  CFA2_2025_NOTES: '/exams/cfa2/2025/notes',
  CFA2_2025_NEW_NOTES: '/exams/cfa2/2025/notes/new-notes',
  CFA2_2025_NOTES_UPDATE: '/exams/cfa2/2025/notes/update/:id/:chapter_id',
  CFA2_2025_NOTES_VIEW: '/exams/cfa2/2025/notes/view/:id',
  /*
/*

---------------------------------
             CFA2_2025_VIDEOS
---------------------------------
*/
  CFA2_2025_VIDEOS: '/exams/cfa2/2025/videos',
  CFA2_2025_NEW_VIDEOS: '/exams/cfa2/2025/videos/new-video',
  CFA2_2025_VIDEOS_UPDATE: '/exams/cfa2/2025/videos/update/:id/:chapter_id',
  CFA2_2025_VIDEOS_VIEW: '/exams/cfa2/2025/videos/view/:id',

  /*
---------------------------------
             CFA3_NOTES
---------------------------------
*/
  CFA3_NOTES: '/exams/cfa3/notes',
  CFA3_NEW_NOTES: '/exams/cfa3/notes/new-notes',
  CFA3_NOTES_UPDATE: '/exams/cfa3/notes/update/:id/:chapter_id',
  CFA3_NOTES_VIEW: '/exams/cfa3/notes/view/:id',

  CFA3_2025_NOTES: '/exams/cfa3/2025/notes',
  CFA3_2025_NEW_NOTES: '/exams/cfa3/2025/notes/new-notes',
  CFA3_2025_NOTES_UPDATE: '/exams/cfa3/2025/notes/update/:id/:chapter_id',
  CFA3_2025_NOTES_VIEW: '/exams/cfa3/2025/notes/view/:id',
  /*

---------------------------------
             CFA2_2023_VIDEOS
---------------------------------
*/
  CFA2_2023_VIDEOS: '/exams/cfa2-2023/videos',
  CFA2_2023_NEW_VIDEOS: '/exams/cfa2-2023/videos/new-video',
  CFA2_2023_VIDEOS_UPDATE: '/exams/cfa2-2023/videos/update/:id/:chapter_id',
  CFA2_2023_VIDEOS_VIEW: '/exams/cfa2-2023/videos/view/:id',
  /*     <===================  CFA3  ====================>  */

  /*
  ---------------------------------
                CFA2_CHAPTERS
  ---------------------------------
*/
  CFA3_CHAPTERS: '/exams/cfa3/chapter',
  CFA3_2025_CHAPTERS: '/exams/cfa3/2025/chapter',

  /*
  ---------------------------------
                CFA3_CASES
  ---------------------------------
*/
  CFA3_CASES: '/exams/cfa3/cases',
  CFA3_CASES_UPDATE: '/exams/cfa3/cases/update/:id/:chapter_id',
  CFA3_CASES_VIEW: '/exams/cfa3/cases/view/:id',
  CFA3_NEW_CASES: '/exams/cfa3/cases/new-cases',

  CFA3_2025_CASES: '/exams/cfa3/2025/cases',
  CFA3_2025_CASES_UPDATE: '/exams/cfa3/2025/cases/update/:id/:chapter_id',
  CFA3_2025_CASES_VIEW: '/exams/cfa3/2025/cases/view/:id',
  CFA3_2025_NEW_CASES: '/exams/cfa3/2025/cases/new-cases',

  CBT3_MOCK: '/exams/cfa3/cbt/mock',
  CBT3_QUESTIONS: '/exams/cfa3/cbt/question',
  CBT3_QUESTION_VIEW: '/exams/cfa3/cbt/question/view/:id',
  CBT3_QUESTION_ESSAY_VIEW: '/exams/cfa3/cbt/question-essay/view/:id',

  CFA3_CBT_CASES: '/exams/cfa3/cbt/cases',
  CFA3_CBT_NEW_CASES: '/exams/cfa3/cbt/cases/new-cases',
  CFA3_CBT_Cases_Update: '/exams/cfa3/cbt/cases/update/:id/:chapter_id',
  CFA3_CBT_CASES_VIEW: '/exams/cfa3/cbt/cases/view/:id',
  /*
  ---------------------------------
                CFA3_QUESTIONS
  ---------------------------------
*/
  CFA3_QUESTIONS: '/exams/cfa3/questions',
  CFA3_NEW_QUES: '/exams/cfa3/questions/new-ques',
  CFA3_QUES_UPDATE: '/exams/cfa3/questions/update/:id/:chapter_id',
  CFA3_QUES_VIEW: '/exams/cfa3/questions/view/:id',

  CFA3_2025_QUESTIONS: '/exams/cfa3/2025/questions',
  CFA3_2025_NEW_QUES: '/exams/cfa3/2025/questions/new-ques',
  CFA3_2025_QUES_UPDATE: '/exams/cfa3/2025/questions/update/:id/:chapter_id',
  CFA3_2025_QUES_VIEW: '/exams/cfa3/2025/questions/view/:id',
  /*
  ----------------------------
      CFA-3 MOCK EXAMS
  ----------------------------
  */
  CFA3_MOCK_EXAMS: '/exams/cfa3/mock-exams',

  /*
  ---------------------------------
                CFA3_VIDEOS
  ---------------------------------
*/
  CFA3_VIDEOS: '/exams/cfa3/videos',
  CFA3_VIDEOS_VIEW: '/exams/cfa3/video/view/:id',
  CFA3_VIDEOS_ADD: '/exams/cfa3/video/add',
  CFA3_VIDEOS_UPDATE: '/exams/cfa3/video/update/:id/:chapter_id',
  CFA3_2025_VIDEOS: '/exams/cfa3/2025/videos',
  CFA3_2025_VIDEOS_VIEW: '/exams/cfa3/2025/video/view/:id',
  CFA3_2025_VIDEOS_ADD: '/exams/cfa3/2025/video/add',
  CFA3_2025_VIDEOS_UPDATE: '/exams/cfa3/2025/video/update/:id/:chapter_id',
  CFA3_VIDEOS_REPORT_UPDATE:
    '/reports/reports-view/cfa3/video/update/:id/:chapter_id/:reportID',
  CFA3_2025_VIDEOS_REPORT_UPDATE:
    '/reports/reports-view/cfa3/2025/video/update/:id/:chapter_id/:reportID',

  /*
  ---------------------------------
                CFA3_QUESTIONS
  ---------------------------------
*/
  CFA3_ESSAY_QUESTION: '/exams/cfa3/essay/questions',
  CFA3_ESSAY_NEW_QUES: '/exams/cfa3/essay/questions/new-ques',
  CFA3_ESSAY_QUES_UPDATE: '/exams/cfa3/essay/questions/update/:id/:chapter_id',
  CFA3_ESSAY_QUES_VIEW: '/exams/cfa3/essay/questions/view/:id',
  CFA3_2025_ESSAY_QUESTION: '/exams/cfa3/2025/essay/questions',
  CFA3_2025_ESSAY_NEW_QUES: '/exams/cfa3/2025/essay/questions/new-ques',
  CFA3_2025_ESSAY_QUES_UPDATE:
    '/exams/cfa3/2025/essay/questions/update/:id/:chapter_id',
  CFA3_2025_ESSAY_QUES_VIEW: '/exams/cfa3/2025/essay/questions/view/:id',

  REPORT_CFA3_ESSAY_QUES_UPDATE:
    '/reports/reports-view/cfa-level-3/essay-question/update/:id/:chapter_id',

  /*
  ---------------------------------
                CFA3_CASES
  ---------------------------------
*/
  CFA3_ESSAY_CASES: '/exams/cfa3/essay/cases',
  CFA3_ESSAY_CASES_UPDATE: '/exams/cfa3/essay/cases/update/:id/:chapter_id',
  CFA3_ESSAY_CASES_VIEW: '/exams/cfa3/essay/cases/view/:id',
  CFA3_ESSAY_NEW_CASES: '/exams/cfa3/essay/cases/new-cases',

  CFA3_2025_ESSAY_CASES: '/exams/cfa3/2025/essay/cases',
  CFA3_2025_ESSAY_CASES_UPDATE:
    '/exams/cfa3/2025/essay/cases/update/:id/:chapter_id',
  CFA3_2025_ESSAY_CASES_VIEW: '/exams/cfa3/2025/essay/cases/view/:id',
  CFA3_2025_ESSAY_NEW_CASES: '/exams/cfa3/2025/essay/cases/new-cases',

  /*     <======================  FRM1  ======================>  */
  /*
  ---------------------------------
                FRM1_CHAPTERS
  ---------------------------------
*/
  FRM1_CHAPTERS: '/exams/frm1/chapter',
  FRM1_FORMULA_SHEET: '/exams/frm1/formulasheet',

  /*
---------------------------------
              FRM1_READINGS
---------------------------------
*/
  FRM1_READINGS: '/exams/frm1/reading',
  /*
  ---------------------------------
                FRM1_QUESTIONS
  ---------------------------------
*/
  FRM1_QUESTIONS: '/exams/frm1/questions',
  FRM1_NEW_QUES: '/exams/frm1/questions/new-ques',
  FRM1_QUES_UPDATE: '/exams/frm1/questions/update/:id/:chapter_id',
  FRM1_QUES_VIEW: '/exams/frm1/questions/view/:id',

  /*
  ---------------------------------
                FRM1_NOTES
  ---------------------------------
*/
  FRM1_NOTES: '/exams/frm1/notes',
  FRM1_NEW_NOTES: '/exams/frm1/notes/new-notes',
  FRM1_NOTES_UPDATE: '/exams/frm1/notes/update/:id/:chapter_id',
  FRM1_NOTES_VIEW: '/exams/frm1/notes/view/:id',

  /*
  ---------------------------------
                FRM1_VIDEOS
  ---------------------------------
*/
  FRM1_VIDEOS: '/exams/frm1/videos',
  FRM1_NEW_VIDEOS: '/exams/frm1/videos/new-video',
  FRM1_VIDEO_UPDATE: '/exams/frm1/videos/update/:id/:chapter_id',
  FRM1_VIDEOS_VIEW: '/exams/frm1/videos/view/:id',

  /*
  ---------------------------------
                FRM2_VIDEOS
  ---------------------------------
*/
  FRM2_VIDEOS: '/exams/frm2/videos',
  FRM2_NEW_VIDEOS: '/exams/frm2/videos/new-video',
  FRM2_VIDEO_UPDATE: '/exams/frm2/videos/update/:id/:chapter_id',
  FRM2_VIDEOS_VIEW: '/exams/frm2/videos/view/:id',

  /*
  ----------------------------
      FRM-1 MOCK EXAMS
  ----------------------------
  */
  FRM1_MOCK_EXAMS: '/exams/frm1/mock-exams',

  /* <==========================  FRM2 ======================>  */
  /*
  ---------------------------------
                FRM2_CHAPTERS
  ---------------------------------
*/
  FRM2_CHAPTERS: '/exams/frm2/chapter',
  /*
---------------------------------
              FRM2_READINGS
---------------------------------
*/
  FRM2_READINGS: '/exams/frm2/reading',

  /*
  ---------------------------------
                FRM2_QUESTIONS
  ---------------------------------
*/
  FRM2_QUESTIONS: '/exams/frm2/questions',
  FRM2_NEW_QUES: '/exams/frm2/questions/new-ques',
  FRM2_QUES_UPDATE: '/exams/frm2/questions/update/:id/:chapter_id',
  FRM2_QUES_VIEW: '/exams/frm2/questions/view/:id',
  /*
  ---------------------------------
                FRM2_NOTES
  ---------------------------------
*/
  FRM2_NOTES: '/exams/frm2/notes',
  FRM2_NEW_NOTES: '/exams/frm2/notes/new-notes',
  FRM2_NOTES_UPDATE: '/exams/frm2/notes/update/:id/:chapter_id',
  FRM2_NOTES_VIEW: '/exams/frm2/notes/view/:id',
  /*
  ---------------------------------
  | | | | | | | FRM2_FORMULA_SHEET
  ---------------------------------
  */
  FRM2_FORMULA_SHEET: '/exams/frm2/formulasheet',

  /*
  
  ----------------------------
      FRM-2 MOCK EXAMS
  ----------------------------
  */
  FRM2_MOCK_EXAMS: '/exams/frm2/mock-exams',
  /*   <===============Actuarial- P ==================>*/
  /*
  ---------------------------------
               ACTUARIAL - P_CHAPTERS
  ---------------------------------
*/
  ACTUARIALP_CHAPTERS: '/exams/actuarialp/chapter',
  /*
---------------------------------
             ACTUARIAL - P_READINGS
---------------------------------
*/
  ACTUARIALP_READINGS: '/exams/actuarialp/reading',
  /*
  ---------------------------------
                ACTUARIAL - P_QUESTIONS
  ---------------------------------
*/
  ACTUARIALP_QUESTIONS: '/exams/actuarialp/questions',
  ACTUARIALP_NEW_QUES: '/exams/actuarialp/questions/new-ques',
  ACTUARIALP_QUES_UPDATE: '/exams/actuarialp/questions/update/:id/:chapter_id',
  ACTUARIALP_QUES_VIEW: '/exams/actuarialp/questions/view/:id',

  /*
  ---------------------------------
                ACTUARIAL - P_NOTES
  ---------------------------------
*/
  ACTUARIALP_NOTES: '/exams/actuarialp/notes',
  ACTUARIALP_NEW_NOTES: '/exams/actuarialp/notes/new-notes',
  ACTUARIALP_NOTES_UPDATE: '/exams/actuarialp/notes/update/:id/:chapter_id',
  ACTUARIALP_NOTES_VIEW: '/exams/actuarialp/notes/view/:id',

  /*     <=================  Actuarial- FM ===================> */

  /*
  ---------------------------------
              ACTUARIAL - PROBABILITY VIDEOS
  ---------------------------------
*/
  ACTUARIAL_P_VIDEOS: '/exams/actuarial_p/videos',
  ACTUARIAL_P_VIDEOS_VIEW: '/exams/actuarial_p/video/view/:id',
  ACTUARIAL_P_VIDEOS_ADD: '/exams/actuarial_p/video/add',
  ACTUARIAL_P_VIDEOS_UPDATE: '/exams/actuarial_p/video/update/:id/:chapter_id',
  ACTUARIAL_P_VIDEOS_REPORT_UPDATE:
    '/reports/reports-view/actuarial_p/video/update/:id/:chapter_id/:reportID',
  /*
  ---------------------------------
              ACTUARIAL - FM_CHAPTERS
  ---------------------------------
*/
  ACTUARIALFM_CHAPTERS: '/exams/actuarialfm/chapter',

  /*
  ---------------------------------
              ACTUARIAL - FM_QUESTIONS
  ---------------------------------
*/
  ACTUARIALFM_QUESTIONS: '/exams/actuarialfm/questions',
  ACTUARIALFM_NEW_QUES: '/exams/actuarialfm/questions/new-ques',
  ACTUARIALFM_QUES_UPDATE:
    '/exams/actuarialfm/questions/update/:id/:chapter_id',
  ACTUARIALFM_QUES_VIEW: '/exams/actuarialfm/questions/view/:id',
  /*
  ---------------------------------
               ACTUARIAL - FM_NOTES
  ---------------------------------
*/
  ACTUARIALFM_NOTES: '/exams/actuarialfm/notes',
  ACTUARIALFM_NEW_NOTES: '/exams/actuarialfm/notes/new-notes',
  ACTUARIALFM_NOTES_UPDATE: '/exams/actuarialfm/notes/update/:id/:chapter_id',
  ACTUARIALFM_NOTES_VIEW: '/exams/actuarialfm/notes/view/:id',
  /*
  ---------------------------------
               ACTUARIAL - FM_VIDEOS
  ---------------------------------
*/
  ACTUARIALFM_VIDEOS: '/exams/actuarialfm/videos',
  ACTUARIALFM_NEW_VIDEOS: '/exams/actuarialfm/videos/new-video',
  ACTUARIALFM_VIDEOS_UPDATE: '/exams/actuarialfm/videos/update/:id',
  ACTUARIALFM_VIDEOS_VIEW: '/exams/actuarialfm/videos/view/:id',
  /*      <=================  Actuarial- IFM ===============>  */
  /*
  ---------------------------------
               ACTUARIAL - IFM_CHAPTERS
  ---------------------------------
*/
  ACTUARIALIFM_CHAPTERS: '/exams/actuarialifm/chapter',
  /*
  ---------------------------------
              ACTUARIAL - IFM_QUESTIONS
  ---------------------------------
*/ ACTUARIALIFM_QUESTIONS:
    '/exams/actuarialifm/questions',
  ACTUARIALIFM_NEW_QUES: '/exams/actuarialifm/questions/new-ques',
  ACTUARIALIFM_QUES_UPDATE:
    '/exams/actuarialifm/questions/update/:id/:chapter_id',
  ACTUARIALIFM_QUES_VIEW: '/exams/actuarialifm/questions/view/:id',
  /*
  ---------------------------------
               ACTUARIAL - IFM_NOTES
  ---------------------------------
*/
  ACTUARIALIFM_NOTES: '/exams/actuarialifm/notes',
  ACTUARIALIFM_NEW_NOTES: '/exams/actuarialifm/notes/new-notes',
  ACTUARIALIFM_NOTES_UPDATE: '/exams/actuarialifm/notes/update/:id/:chapter_id',
  ACTUARIALIFM_NOTES_VIEW: '/exams/actuarialifm/notes/view/:id',

  /*
----------------
      FORUM
----------------
  */
  FORUM: '/forum',
  /*
----------------
      FORUM - POST 
----------------
  */
  // FM_STATUS: '/forum/status',
  FM_NEW_POSTSINDEX: '/forum/status-post',
  FM_NEW_POSTS: '/forum/status-post/post-view',
  POSTS_EDIT: '/forum/post/edit/:id',
  POSTS_VIEW: '/forum/post/view/:id/:isCommented',
  /*
----------------
     FORUM - COMMENT 
----------------
 */
  POST_COMMENTINDEX: '/forum/status/comments',
  FM_COMMENTS: '/forum/status/comments-view',
  COMMENTS_EDIT: '/forum/comments/edit/:id',
  COMMENTS_VIEW:
    '/forum/status-post/post-view/comments/view/:postId/:id/:isCommented/:color/:caseID/:exam',
  Forum_CFA1_Ques_Update:
    '/forum/status-post/post-view/cfa-level-1/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_CFA1_2022_Ques_Update:
    '/forum/status-post/post-view/cfa-level-1-2022/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_CFA1_2023_Ques_Update:
    '/forum/status-post/post-view/cfa-level-1-2023/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_CFA2_Ques_Update:
    '/forum/status-post/post-view/cfa-level-2/question/edit/:id/:chapter_id/:caseID/:postID/:color/:exam',
  Forum_CFA2_2022_Ques_Update:
    '/forum/status-post/post-view/cfa-level-2-2022/question/edit/:id/:chapter_id/:caseID/:postID/:color/:exam',
  Forum_CFA2_2023_Ques_Update:
    '/forum/status-post/post-view/cfa-level-2-2023/question/edit/:id/:chapter_id/:caseID/:postID/:color/:exam',
  Forum_CFA3_Ques_Update:
    '/forum/status-post/post-view/cfa-level-3/question/edit/:id/:chapter_id/:caseID/:postID/:color/:exam',
  Forum_FRM_QUES_Update:
    '/forum/frm-part-1-2/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_ACTUARIALP_Ques_Update:
    '/forum/actuarial-pprobability/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_ACTUARIALFM_Ques_Update:
    '/forum/actuarial-fmfinancial-mathematics/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_ACTUARIALIFM_Ques_Update:
    '/forum/actuarial-ifminvestment-and-financial-markets/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_GMAT_Ques_Update:
    '/forum/gmat/question/edit/:id/:chapter_id/:postID/:color/:exam',
  Forum_EA_Ques_Update:
    '/forum/ea/question/edit/:id/:chapter_id/:postID/:color/:exam',
  // Validate Changes
  Forum_CFA1_Ques_Validate_Update:
    '/forum/post-view/question-validate/:id/:chapter_id/:postID/:updatedBy/:exam',

  Forum_Case_Ques_Update:
    '/forum/post-view/question-validate-case/:id/:chapter_id/:postID/:updatedBy/:exam/:caseID',
  /*
----------------
    Reports  
----------------
  */
  ReportsIndex: '/reports',
  Reports: '/reports/reports-view',
  /*
-----------------
Reports questions
----------------
*/
  Reports_CFA1_Ques_Update:
    '/reports/reports-view/cfa-level-1/question-update/:id/:chapter_id/:reportID/:d',
  Reports_GMAT_Ques_Update:
    '/reports/reports-view/gmat/question-update/:id/:chapter_id/:reportID/:d/:reading_id/:case_id/:gmat_type',
  Reports_EA_Ques_Update:
    '/reports/reports-view/ea/question-update/:id/:chapter_id/:reportID/:d/:reading_id/:case_id/:ea_type',
  Reports_CFA1_2022_Ques_Update:
    '/reports/reports-view/cfa-level-1-2022/question-update/:id/:chapter_id/:reportID/:d',
  Reports_CFA1_2023_Ques_Update:
    '/reports/reports-view/cfa-level-1-2023/question-update/:id/:chapter_id/:reportID/:d',
  Reports_CFA2_2022_Cases_Ques_Update:
    '/reports/reports-view/cfa-level-2-2022/question-update/:id/:caseID/:chapter_id/:reportID/:d',
  Reports_CFA2_2023_Cases_Ques_Update:
    '/reports/reports-view/cfa-level-2-2023/question-update/:id/:caseID/:chapter_id/:reportID/:d',
  Reports_CFA2_2025_Cases_Ques_Update:
    '/reports/reports-view/cfa-level-2/2025/question-update/:id/:caseID/:chapter_id/:reportID/:d',

  Report_CFA1_CBT_Ques_Update:
    '/reports/reports-view/cfa-level-1/cbt-question-update/:id/:chapter_id/:reportID',

  Report_CFA1_2022_CBT_Ques_Update:
    '/reports/reports-view/cfa-level-1-2022/cbt-question-update/:id/:chapter_id/:reportID',
  Report_CFA1_2023_CBT_Ques_Update:
    '/reports/reports-view/cfa-level-1-2023/cbt-question-update/:id/:chapter_id/:reportID',

  Report_CFA2_CBT_Ques_Update:
    '/reports/reports-view/cfa-level-2/cbt-question-update/:id/:chapter_id/:reportID',

  Report_CFA3_CBT_ESSAY_Ques_Update:
    '/reports/reports-view/cfa-level-3/cbt-essay-question-update/:id/:reportID',
  Report_CFA3_CBT_NORMAL_Ques_Update:
    '/reports/reports-view/cfa-level-3/cbt-normal-question-update/:id/:reportID',

  Report_CFA2_2022_CBT_Ques_Update:
    '/reports/reports-view/cfa-level-2-2022/cbt-question-update/:id/:chapter_id/:reportID',
  Report_CFA2_2023_CBT_Ques_Update:
    '/reports/reports-view/cfa-level-2-2023/cbt-question-update/:id/:chapter_id/:reportID',
  Reports_CFA2_Cases_Ques_Update:
    '/reports/reports-view/cfa-level-2/question-update/:id/:caseID/:chapter_id/:reportID/:d',
  Reports_CFA3_Cases_Ques_Update:
    '/reports/reports-view/cfa-level-3/question-update/:id/:caseID/:chapter_id/:reportID/:d',
  Reports_CFA3_2025_Cases_Ques_Update:
    '/reports/reports-view/cfa-level-3/2025/question-update/:id/:caseID/:chapter_id/:reportID/:d',
  Reports_FRM_Ques_Update:
    '/reports/reports-view/frm-part-1-2/question-update/:id/:chapter_id/:reportID/:d',
  Report_FRM1_CBT_Ques_Update:
    '/reports/reports-view/frm-part-1-2/cbt-question-update/:id/:chapter_id/:reportID/:level',
  Reports_ACTUARIALP_Ques_Update:
    '/reports/reports-view/actuarial-pprobability/question-update/:id/:chapter_id/:reportID/:d',
  Reports_ACTUARIALFM_Ques_Update:
    '/reports/reports-view/actuarial-fmfinancial-mathematics/question-update/:id/:chapter_id/:reportID/:d',
  Reports_ACTUARIALIFM_Ques_Update:
    '/reports/reports-view/actuarial-ifminvestment-and-financial-markets/question-update/:id/:chapter_id/:reportID/:d',

  Reports_Ques_Validate:
    '/reports/reports-view/question-validate/:id/:chapter_id/:reportID/:exam/:updatedBy',
  Reports_Case_Validate:
    '/reports/reports-view/case-validate/:id/:caseID/:chapter_id/:reportID/:exam/:updatedBy',
  Reports_Notes_Validate:
    '/reports/reports-view/notes-validate/:id/:chapter_id/:reportID/:exam/:updatedBy',

  /*
  -------------
  Reports notes
  ------------
  */
  Reports_CFA1_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-1/notes-update/:id/:chapter_id/:reportID/:d',

  Reports_CFA1_2022_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-1-2022/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_CFA1_2023_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-1-2023/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_FRM_Notes_Update:
    '/reports/reports-view/frm-part-1-2/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_ACTUARIALP_NOTES_UPDATE:
    '/reports/reports-view/actuarial-pprobability/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_ACTUARIALFM_NOTES_UPDATE:
    '/reports/reports-view/actuarial-fmfinancial-mathematics/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_ACTUARIALIFM_NOTES_UPDATE:
    '/reports/reports-view/actuarial-ifminvestment-and-financial-markets/notes-update/:id/:chapter_id/:reportID/:d',

  Reports_CFA2_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-2/notes-update/:id/:chapter_id/:reportID/:d',

  Reports_CFA2_2022_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-2-2022/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_CFA2_2023_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-2-2023/notes-update/:id/:chapter_id/:reportID/:d',
  Reports_CFA2_2025_NOTES_UPDATE:
    '/reports/reports-view/cfa-level-2/2025/notes-update/:id/:chapter_id/:reportID/:d',

  /*
  -------------
  Reports Video
  -------------
  */
  Reports_CFA1_Videos_Update:
    '/reports/reports-view/cfa-level-1/video-update/:id/:chapter_id/:reportID',
  Reports_GMAT_Videos_Update:
    '/reports/reports-view/gmat/video-update/:id/:chapter_id/:reportID',
  Reports_EA_Videos_Update:
    '/reports/reports-view/ea/video-update/:id/:chapter_id/:reportID',
  Reports_CFA1_2022_Videos_Update:
    '/reports/reports-view/cfa-level-1_2022/video-update/:id/:chapter_id/:reportID',
  Reports_CFA1_2023_Videos_Update:
    '/reports/reports-view/cfa-level-1_2023/video-update/:id/:chapter_id/:reportID',
  Reports_FRM_Videos_Update:
    '/reports/reports-view/frm-part-1-2/video-update/:id/:chapter_id/:reportID',
  Reports_ACTUARIALFM_Videos_Update:
    '/reports/reports-view/actuarial-fmfinancial-mathematics/video-update/:id/:reportID',

  Reports_CFA2_Videos_Update:
    '/reports/reports-view/cfa-level-2/video-update/:id/:chapter_id/:reportID',

  Reports_CFA2_2022_Videos_Update:
    '/reports/reports-view/cfa-level-2-2022/video-update/:id/:chapter_id/:reportID',
  Reports_CFA2_2023_Videos_Update:
    '/reports/reports-view/cfa-level-2-2023/video-update/:id/:chapter_id/:reportID',
  Reports_CFA2_2025_Videos_Update:
    '/reports/reports-view/cfa-level-2/2025/video-update/:id/:chapter_id/:reportID',

  /*
 -----------
  REQUESt
------------
*/
  REQUEST: '/Request',

  /*
  -----------
  Invoices
  ----------
 */
  INVOICES: '/Invoices',
  INVOICES_VIEW: '/Invoices/view/:id',

  /*
  -----------
  Orders
  ----------
 */
  ORDERS: '/orders',
  ORDERS_VIEW: '/orders/view/:id',
  /*
  --------------------------------------------------
                   User Tracking
  --------------------------------------------------
  */
  LOGIN_HISTORY: '/users/appUsers/view-users/:id/user_activity/logins_history',
  PDF_DOWNLOAD: '/users/appUsers/view-users/:id/user_activity/pdf_downloaded',
  QUESTION_ANSWERED:
    '/users/appUsers/view-users/:id/user_activity/questions_answered',
  NOTES_READ: '/users/appUsers/view-users/:id/user_activity/notes_read',
  REPORT_RAISED: '/users/appUsers/view-users/:id/user_activity/reports_raised',
  PPT_DOWNLOAD: '/users/appUsers/view-users/:id/user_activity/ppt_downloaded',
  VIDEO_WATCHED: '/users/appUsers/view-users/:id/user_activity/videos_watched',

  /*
  ----------------------------------------------
                    App sumo
  ---------------------------------------------
 */
  APPSUMO: '/app-sumo',

  /*
  --------------------------------------------------
                   Email Link
  --------------------------------------------------
  */
  EMAILLINK: '/email-link',

  /*
  --------------------------------------------------
                   CFA CBT
  --------------------------------------------------
  */
  CFA1_CBT_MOCK_EXAMS: '/exams/cfa1/cbt',
  CBT_MOCK: '/exams/cfa1/cbt/mock',
  CBT_QUESTIONS: '/exams/cfa1/cbt/question',
  CBT_QUESTION_ADD: '/exams/cfa1/cbt/question/new-ques',
  CBT_QUESTION_VIEW: '/exams/cfa1/cbt/question/view/:id',
  CBT_QUESTION_UPDATE:
    '/exams/cfa1/cbt/question/update/:id/:chapter_id/:mock_id',

  /*
  --------------------------------------------------
                   CFA2 CBT
  --------------------------------------------------
  */
  CFA2_CBT_MOCK_EXAMS: '/exams/cfa2/cbt',
  CBT2_MOCK: '/exams/cfa2/cbt/mock',
  CBT2_QUESTIONS: '/exams/cfa2/cbt/question',
  CBT2_QUESTION_ADD: '/exams/cfa2/cbt/question/new-ques',
  CFA3_CBT_QUESTION_ADD_NORMAL_QUESTION:
    '/exams/cfa3/cbt/question/new-normal-ques',

  CFA3_CBT_QUESTION_ADD: '/exams/cfa3/cbt/question/new-ques',

  CBT2_QUESTION_VIEW: '/exams/cfa2/cbt/question/view/:id',
  CBT2_QUESTION_UPDATE:
    '/exams/cfa2/cbt/question/update/:id/:chapter_id/:mock_id',
  CFA3_CBT_QUESTION_UPDATE:
    '/exams/cfa3/cbt/question/update/:id/:chapter_id/:mock_id',
  CFA3_CBT_ESSAY_QUESTION_UPDATE:
    '/exams/cfa3/cbt/essay-question/update/:id/:chapter_id/:mock_id',
  /*
  ---------------------------------
                CFA2_CASES
  ---------------------------------
*/
  CFA2_CBT_CASES: '/exams/cfa2/cbt/cases',
  CFA2_CBT_NEW_CASES: '/exams/cfa2/cbt/cases/new-cases',
  CFA2_CBT_Cases_Update: '/exams/cfa2/cbt/cases/update/:id/:chapter_id',
  CFA2_CBT_CASES_VIEW: '/exams/cfa2/cbt/cases/view/:id',
  /*
  --------------------------------------------------
                   CFA 2022 CBT
  --------------------------------------------------
  */
  CFA1_2022_CBT_MOCK_EXAMS: '/exams/cfa1-2022/cbt',
  CBT_2022_MOCK: '/exams/cfa1-2022/cbt/mock',
  CBT_2022_QUESTIONS: '/exams/cfa1-2022/cbt/question',
  CBT_2022_QUESTION_ADD: '/exams/cfa1-2022/cbt/question/new-ques',
  CBT_2022_QUESTION_VIEW: '/exams/cfa1-2022/cbt/question/view/:id',
  CBT_2022_QUESTION_UPDATE:
    '/exams/cfa1-2022/cbt/question/update/:id/:chapter_id/:mock_id',

  /*
  --------------------------------------------------
                   CFA 2023 CBT
  --------------------------------------------------
  */
  CFA1_2023_CBT_MOCK_EXAMS: '/exams/cfa1-2023/cbt',
  CBT_2023_MOCK: '/exams/cfa1-2023/cbt/mock',
  CBT_2023_QUESTIONS: '/exams/cfa1-2023/cbt/question',
  CBT_2023_QUESTION_ADD: '/exams/cfa1-2023/cbt/question/new-ques',
  CBT_2023_QUESTION_VIEW: '/exams/cfa1-2023/cbt/question/view/:id',
  CBT_2023_QUESTION_UPDATE:
    '/exams/cfa1-2023/cbt/question/update/:id/:chapter_id/:mock_id',

  /*
  --------------------------------------------------
                   Frm1 CBT
  --------------------------------------------------
  */
  FRM1_CBT_MOCK_EXAMS: '/exams/frm1/cbt',
  FRM1_CBT_MOCK: '/exams/frm1/cbt/mock',
  FRM1_CBT_QUESTIONS: '/exams/frm1/cbt/question',
  FRM1_CBT_QUESTION_ADD: '/exams/frm1/cbt/question/new-ques',
  FRM1_CBT_QUESTION_VIEW: '/exams/frm1/cbt/question/view/:id',
  FRM1_CBT_QUESTION_UPDATE:
    '/exams/frm1/cbt/question/update/:id/:chapter_id/:mock_id',

  /*
  --------------------------------------------------
                   FRM2 CBT
  --------------------------------------------------
  */
  FRM2_CBT_MOCK_EXAMS: '/exams/frm2/cbt',
  FRM2_CBT_MOCK: '/exams/frm2/cbt/mock',
  FRM2_CBT_QUESTIONS: '/exams/frm2/cbt/question',
  FRM2_CBT_QUESTION_ADD: '/exams/frm2/cbt/question/new-ques',
  FRM2_CBT_QUESTION_VIEW: '/exams/frm2/cbt/question/view/:id',
  FRM2_CBT_QUESTION_UPDATE:
    '/exams/frm2/cbt/question/update/:id/:chapter_id/:mock_id',

  /*
  --------------------------------------------------
                   Logs
  --------------------------------------------------
  */
  LOGS: '/logs',
  LOGS_SUMMARY: '/logs/summary/:id',
  LOGS_FULL_SUMMARY_FORUM: '/logs/forum-full-summary',
  LOGS_FULL_SUMMARY_REPORT: '/logs/report-full-summary',
  REPORT_SUMMARY_VIEW: '/report-logs/summary/:id',

  /* <=====================  GMAT  ======================>  */
  /*
---------------------------------
             GMAT_CHAPTERS
---------------------------------
  */
  GMAT_CHAPTERS: '/exams/gmat/chapter',
  /*
---------------------------------
              GMAT_READINGS
---------------------------------
*/
  GMAT_READINGS: '/exams/gmat/reading',
  /*
--------------------------------------
                GMAT_QUESTIONS
-------------------------------------
*/
  GMAT_QUESTIONS: '/exams/gmat/questions',
  GMAT_NEW_QUES: '/exams/gmat/questions/new-ques',
  GMAT_QUES_UPDATE:
    '/exams/gmat/questions/update/:id/:chapter_id/:reading_id/:case_id/:gmat_type',
  GMAT_QUES_VIEW: '/exams/gmat/questions/view/:id/:type',

  /*
---------------------------------
              GMAT_VIDEOS
---------------------------------
*/
  GMAT_VIDEOS: '/exams/gmat/videos',
  GMAT_VIDEOS_VIEW: '/exams/gmat/videos/view/:id',
  GMAT_VIDEOS_UPDATE: '/exams/gmat/videos/update/:id/:chapter_id',
  GMAT_NEW_VIDEOS: '/exams/gmat/videos/new-video',

  /*
---------------------------------
              GMAT_CASES
---------------------------------
*/
  GMAT_CASES: '/exams/gmat/cases',
  GMAT_NEW_CASES: '/exams/gmat/cases/new-cases',
  GMAT_Cases_Update: '/exams/gmat/cases/update/:id/:chapter_id',
  GMAT_CASES_VIEW: '/exams/gmat/cases/view/:id',

  /*

  /* <=====================  EA  ======================>  */
  /*
---------------------------------
             EA_CHAPTERS
---------------------------------
  */
  EA_CHAPTERS: '/exams/ea/chapter',
  /*
---------------------------------
            EA_READINGS
---------------------------------
*/
  EA_READINGS: '/exams/ea/reading',
  /*
--------------------------------------
              EA_QUESTIONS
-------------------------------------
*/
  EA_QUESTIONS: '/exams/ea/questions',
  EA_NEW_QUES: '/exams/ea/questions/new-ques',
  EA_QUES_UPDATE:
    '/exams/ea/questions/update/:id/:chapter_id/:reading_id/:case_id/:ea_type',
  EA_QUES_VIEW: '/exams/ea/questions/view/:id/:type',

  /*
---------------------------------
            EA_VIDEOS
---------------------------------
*/
  EA_VIDEOS: '/exams/ea/videos',
  EA_VIDEOS_VIEW: '/exams/ea/videos/view/:id',
  EA_VIDEOS_UPDATE: '/exams/ea/videos/update/:id/:chapter_id',
  EA_NEW_VIDEOS: '/exams/ea/videos/new-video',

  /*
---------------------------------
            EA_CASES
---------------------------------
*/
  EA_CASES: '/exams/ea/cases',
  EA_NEW_CASES: '/exams/ea/cases/new-cases',
  EA_Cases_Update: '/exams/ea/cases/update/:id/:chapter_id',
  EA_CASES_VIEW: '/exams/ea/cases/view/:id',

  /*
-------------------------------
            Coupons
-------------------------------
  */
  COUPONS: '/coupons',
  /*
--------------------------------------------------------------------------------
                    2024 EXAM ROUTER STARTED HERE
--------------------------------------------------------------------------------
*/
  //  CFA LEVEL 1 STARTED HERE
  CFA1_2024_CHAPTERS: '/exams/cfa1/2024/chapter',
  CFA1_2024_NOTES: '/exams/cfa1/2024/notes',
  CFA1_2024_NOTES_ADD: '/exams/cfa1/2024/notes/add',
  CFA1_2024_NOTES_EDIT:
    '/exams/cfa1/2024/notes/edit/:id/:chapter_id/:reading_id',
  CFA1_2024_REPORTED_NOTES_EDIT:
    '/exams/cfa1/2024/notes/edit/:id/:chapter_id/:reading_id/:report_id',
  CFA1_2024_NOTES_VIEW: '/exams/cfa1/2024/notes/view/:id',

  CFA1_2024_NOTES_UPDATE: '/exams/cfa1/2024/notes/update/:id/:chapter_id',
  CFA1_2024_READING: '/exams/cfa1/2024/readings',

  CFA1_2024_VIDEOS: '/exams/cfa1/2024/videos',
  CFA1_2024_VIDEOS_ADD: '/exams/cfa1/2024/videos/add',
  CFA1_2024_VIDEOS_EDIT:
    '/exams/cfa1/2024/videos/edit/:id/:chapter_id/:reading_id',
  CFA1_2024_VIDEOS_UPDATE: '/exams/cfa1/2024/videos/update/:id/:chapter_id',
  CFA1_2024_VIDEOS_VIEW: '/exams/cfa1/2024/videos/view/:id',

  CFA1_2024_QUESTION_BANK: '/exams/cfa1/2024/questions',
  CFA1_2024_QUESTION_BANK_ADD: '/exams/cfa1/2024/questions/add',
  CFA1_2024_QUESTION_BANK_EDIT:
    '/exams/cfa1/2024/questions/edit/:id/:chapter_id/:reading_id',
  CFA1_2024_REPORT_QUESTION_BANK_EDIT:
    '/exams/cfa1/2024/questions/edit/:id/:chapter_id/:reading_id/:report_id',

  CFA1_2025_REPORT_QUESTION_BANK_EDIT:
    '/exams/cfa1/2025/questions/edit/:id/:chapter_id/:reading_id/:report_id',
  CFA1_2024_QUESTION_BANK_UPDATE:
    '/exams/cfa1/2024/questions/update/:id/:chapter_id',
  CFA1_2024_QUESTION_BANK_VIEW: '/exams/cfa1/2024/questions/view/:id',

  CFA1_2024_MOCK_EXAMS: '/exams/cfa1/2024/mock-exams',
  CFA1_2024_CBT_MOCK_EXAMS: '/exams/cfa1/2024/cbt',
  CBT_2024_MOCK: '/exams/cfa1/2024/cbt/mock',
  CBT_2024_QUESTIONS: '/exams/cfa1/2024/cbt/question',
  CBT_2024_QUESTION_ADD: '/exams/cfa1/2024/cbt/question/new-ques',
  CBT_2024_QUESTION_VIEW: '/exams/cfa1/2024/cbt/question/view/:id',
  CBT_2024_QUESTION_UPDATE:
    '/exams/cfa1/2024/cbt/question/update/:id/:chapter_id/:mock_id',

  // CFA LEVEL 1 2025
  CFA1_2025_CHAPTERS: '/exams/cfa1/2025/chapter',
  CFA1_2025_NOTES: '/exams/cfa1/2025/notes',
  CFA1_2025_NOTES_ADD: '/exams/cfa1/2025/notes/add',
  CFA1_2025_NOTES_EDIT:
    '/exams/cfa1/2025/notes/edit/:id/:chapter_id/:reading_id',
  CFA1_2025_REPORTED_NOTES_EDIT:
    '/exams/cfa1/2025/notes/edit/:id/:chapter_id/:reading_id/:report_id',
  CFA1_2025_NOTES_VIEW: '/exams/cfa1/2025/notes/view/:id',

  CFA1_2025_NOTES_UPDATE: '/exams/cfa1/2025/notes/update/:id/:chapter_id',
  CFA1_2025_READING: '/exams/cfa1/2025/readings',

  CFA1_2025_VIDEOS: '/exams/cfa1/2025/videos',
  CFA1_2025_VIDEOS_ADD: '/exams/cfa1/2025/videos/add',
  CFA1_2025_VIDEOS_EDIT:
    '/exams/cfa1/2025/videos/edit/:id/:chapter_id/:reading_id',
  CFA1_2025_VIDEOS_UPDATE: '/exams/cfa1/2025/videos/update/:id/:chapter_id',
  CFA1_2025_VIDEOS_VIEW: '/exams/cfa1/2025/videos/view/:id',

  CFA1_2025_CBT_VIDEOS: '/exams/cfa1/2025/cbt/videos',
  CFA1_2025_CBT_VIDEOS_ADD: '/exams/cfa1/2025/cbt/videos/add',
  CFA1_2025_CBT_VIDEOS_EDIT: '/exams/cfa1/2025/cbt/videos/edit/:id/:mockId',
  CFA1_2025_CBT_VIDEOS_VIEW: '/exams/cfa1/2025/cbt/videos/view/:id',

  CFA1_2024_CBT_VIDEOS: '/exams/cfa1/2024/cbt/videos',
  CFA1_2024_CBT_VIDEOS_ADD: '/exams/cfa1/2024/cbt/videos/add',
  CFA1_2024_CBT_VIDEOS_EDIT: '/exams/cfa1/2024/cbt/videos/edit/:id/:mockId',
  CFA1_2024_CBT_VIDEOS_VIEW: '/exams/cfa1/2024/cbt/videos/view/:id',

  CFA1_2025_QUESTION_BANK: '/exams/cfa1/2025/questions',
  CFA1_2025_QUESTION_BANK_ADD: '/exams/cfa1/2025/questions/add',
  CFA1_2025_QUESTION_BANK_EDIT:
    '/exams/cfa1/2025/questions/edit/:id/:chapter_id/:reading_id',
  CFA1_2025_QUESTION_BANK_UPDATE:
    '/exams/cfa1/2025/questions/update/:id/:chapter_id',
  CFA1_2025_QUESTION_BANK_VIEW: '/exams/cfa1/2025/questions/view/:id',

  CFA1_2025_MOCK_EXAMS: '/exams/cfa1/2025/mock-exams',
  CFA1_2025_CBT_MOCK_EXAMS: '/exams/cfa1/2025/cbt',
  CBT_2025_MOCK: '/exams/cfa1/2025/cbt/mock',
  CBT_2025_QUESTIONS: '/exams/cfa1/2025/cbt/question',
  CBT_2025_QUESTION_ADD: '/exams/cfa1/2025/cbt/question/new-ques',
  CBT_2025_QUESTION_VIEW: '/exams/cfa1/2025/cbt/question/view/:id',
  CBT_2025_QUESTION_UPDATE:
    '/exams/cfa1/2025/cbt/question/update/:id/:chapter_id/:mock_id',

  //  CFA LEVEL 1  ENDED  HERE

  /*
--------------------------------------------------------------------------------
                    2024 EXAM ROUTER STARTED HERE
--------------------------------------------------------------------------------
*/

  //* ------------------------ {{ HERE GMAT FOCUS ROUTE STARTED }} --------------------------
  EXAM_GMAT_FOCUS: '/exams/gmat-focus',
  GMAT_FOCUS_CHAPTERS: '/exams/gmat-focus/chapter',
  GMAT_FOCUS_READINGS: '/exams/gmat-focus/reading',
  GMAT_FOCUS_QUESTIONS: '/exams/gmat-focus/questions',
  GMAT_FOCUS_NEW_QUES: '/exams/gmat-focus/questions/new-ques',
  GMAT_FOCUS_QUES_UPDATE:
    '/exams/gmat-focus/questions/update/:id/:chapter_id/:reading_id/:case_id/:gmat_type',
  GMAT_FOCUS_REPORT_QUES_UPDATE:
    '/exams/gmat-focus/:reportId/questions/update/:id/:chapter_id/:reading_id/:case_id/:gmat_type',
  GMAT_FOCUS_QUES_VIEW: '/exams/gmat-focus/questions/view/:id/:type',
  GMAT_FOCUS_VIDEOS: '/exams/gmat-focus/videos',
  GMAT_FOCUS_VIDEOS_VIEW: '/exams/gmat-focus/videos/view/:id',
  GMAT_FOCUS_VIDEOS_UPDATE: '/exams/gmat-focus/videos/update/:id/:chapter_id',
  GMAT_FOCUS_NEW_VIDEOS: '/exams/gmat-focus/videos/new-video',
  GMAT_FOCUS_CASES: '/exams/gmat-focus/cases',
  GMAT_FOCUS_NEW_CASES: '/exams/gmat-focus/cases/new-cases',
  GMAT_FOCUS_Cases_Update: '/exams/gmat-focus/cases/update/:id/:chapter_id',
  GMAT_FOCUS_CASES_VIEW: '/exams/gmat-focus/cases/view/:id',
  DOWNLOAD_PREMIUM_USER: '/users/download-premium-user',
};
